@font-face {
  font-family: "suisse-intl";
  src: url("SuisseIntl-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "suisse-intl";
  src: url("SuisseIntl-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: bold;
}
@font-face {
  font-family: "suisse-intl-mono";
  src: url("SuisseIntlMono-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "suisse-intl-mono";
  src: url("SuisseIntlMono-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: bold;
}
